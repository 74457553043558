<template>
  <div class="l-scratch">
    <div class="c-battle_main"
         :style="{'width': battleWidth+'px','height': battleHeight+'px','border-radius': 20*ss+'px',}">
      <!-- region with h/w=0.5625 the same as 1080/1920 -->
      <div class="c-battle_main"
           :style="{'width': realWidth+'px','height': realHeight+'px',}">
        <!-- Main iFrame -->
        <iframe v-if="casinoGames[gameName]"
                id="mainframe"
                allow="clipboard-read; clipboard-write"
                style="width: 100%;height: 100%;border: none;background: black;"
                :src="casinoGames[gameName].uri"
        />
      </div>
    </div>
    <div style="
        z-index: 0;
        position: relative;"
    >
      <other-games class="l-section l-scratch__swiper"/>
      <section class="l-section l-scratch__recent-bets">
        <img :src="chips_red" alt=""/>

        <div class="l-scratch__recent-bets-title-wrap">
          💸
          <div class="l-scratch__recent-bets-title">
            {{ $t("general.recent_bets") }}
          </div>
        </div>
        <img id="svg_coin_2" alt=""
             :src="require('../../assets/svg-icons/svg_coin_2.svg')"/>
        <recent-bets-table
            show-all-collumns
            wrapper-classes="c-recent-bets--game-page"
            class="l-section l-scratch__recent-bets"
        />
        <img
            src="../../assets/images/img_coin_1.webp"
            alt=""
            style="position: absolute;
                  top:30px;
                "
        />
        <img :src="require('../../assets/svg-icons/svg_coin_3.svg')"
             style="position: absolute;
                  width: 93px;
                  height: 65px;
                  right: 50%;
                  bottom: -80px;
                "
             alt=""/>
        <img :src="require('../../assets/svg-icons/svg_chip_blue.svg')"
             style="position: absolute;
                  width: 124px;
                  height: 78px;
                  left: 50%;
                  top: -130px;
                "
             alt=""/>
        <img :src="require('../../assets/svg-icons/svg_chip_violet.svg')"
             style="position: absolute;
                  width: 105px;
                  height: 76px;
                  right: 30%;
                  top: -660px;
                "
             alt=""/>
      </section>
    </div>
  </div>
</template>

<script>
import RecentBetsTable from "../../components/recent-bets/recent-bets-table";
import OtherGames from "../../components/other-games/other-games";
import chips_red from "../../assets/svg/chips_red.svg";

import {casinoGames} from "../../constants/index";

export default {
  name: "GamePage",
  components: {
    RecentBetsTable,
    OtherGames,
  },
  data() {
    return {
      casinoGames:casinoGames, chips_red: chips_red,
      nextUser: {},
      viewportHeight: window.innerHeight,
      realHeight: 1080, realWidth: 1920, ss: 1,    //size of iFrame
      battleWidth: 1920, battleHeight: 1080,     //size of c-battle_main
    };
  },
  computed: {
    gameName() {
      return this.$route.params.gameId.split("-").join("");
    },
    loggedIn() {
      return this.$store.getters.getLoginData != null;
    },
    chips() {
      return this.$store.getters.getChipsBalance;
    },
    unlockedGames() {
      return this.$store.getters.getUnlockedGames;
    },
  },
  watch: {
    async loggedIn(value) {
      if (!value) {
        this.$router.push('/');
      }
    },
    chips(value) {
      if (this.gameWindow) {
        this.gameWindow.postMessage(
            JSON.stringify({action: "balance", balance: value}),
            "*"
        );
      }
    },
    unlockedGames(value) {
      if (!value.includes(this.gameName)) {
        this.$router.push('/');
      }
    }
  },
  beforeMount() {
    if (!localStorage.getItem("loginData")) {
      this.$router.push('/');
    }
    window.addEventListener("message", this.receiveMessage, false);
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();

  },
  beforeUnmount() {
    window.removeEventListener("message", this.receiveMessage);
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.resize.bind(this));
  },
  mounted() {
    if (!localStorage.getItem("loginData")) {
      this.$router.push('/');
    }
    if (this.$store.getters.getIsGuestAndOutOfBets) {
      this.$router.push('/');
    }
    if (!this.unlockedGames.includes(this.gameName)) {
      this.$router.push('/');
    }
    this.scrollUp();
    this.viewportHeight = window.innerHeight;
  },
  methods: {
    getEnv() {
      let url = window.location.hostname;
      if (url.includes("test.") || url.includes("localhost")) {
        return "test";
      } else {
        return "prod";
      }
    },
    resize() {
      let appWidth = window.innerWidth - 16;       //16 scrollbar fixed size
      let appHeight = window.innerHeight - 90;      //90 blockspin logo fixed height
      let orient = appWidth < appHeight ? 'V' : 'H';

      if (appHeight / appWidth > 0.5625)
        appHeight = appWidth * 0.5625;

      let perW = appWidth / 1920;         //70px height of blockspin logo
      let perH = appHeight / 1080;
      let ss = Math.min(perW, perH);
      if (perW < perH) {
        this.realWidth = appWidth;
        this.realHeight = 1080 * perW;
      } else {
        this.realWidth = 1920 * perH;
        this.realHeight = appHeight;
      }

      this.ss = ss;
      this.battleWidth = appWidth;
      this.battleHeight = appHeight;
    },
    scrollBot() {
      window.scroll(0, 0);
    },
    scrollUp() {
      const scrollTo = document.getElementById("header");
      if (scrollTo) {
        scrollTo.scrollIntoView();
      }
    },
    scrollTop() {
      window.scrollBy(0, 300);
    },
    async receiveMessage(message) {
      if (typeof message.data !== "string") {
        return;
      }
      const data = JSON.parse(message.data);
      MyLog('receiveMessage:');
      MyLog(message.data);
      switch (data.action) {
        case "initblackjack": {
          this.gameWindow = message.source;
          if (this.$store.getters.getLoginData) {
            const initBlackjackData = {
              action: 'initblackjack',
              loginData: this.$store.getters.getLoginData,
              env: this.getEnv(),
              sound: this.$store.getters.getSound,
              music: this.$store.getters.getMusic,
            }
            const msg=JSON.stringify(initBlackjackData);
            MyLog('sendMessage:');
            MyLog(msg);
            message.source.postMessage(msg, "*");
          } else {
            this.initOnLogin = true;
          }
          break;
        }
        case "balance": {
          let chips = data.chips;
          this.$store.commit("setChips", chips);
          setTimeout(() => {
            this.$store.dispatch('rewardsUpdate');
          }, 2000);
          break;
        }
        case "home": {
          this.$router.push("/");
          break;
        }
        case "soundOn": {
          this.$store.commit("setSound", "on");
          break;
        }
        case "soundOff": {
          this.$store.commit("setSound", "off");
          break;
        }
        case "musicOn": {
          this.$store.commit("setMusic", "on");
          break;
        }
        case "musicOff": {
          this.$store.commit("setMusic", "off");
          break;
        }
      }

    },
    handleScroll(event) {
      //console.log(event);
    }
  },
};
</script>

<style lang="scss" scoped>
.l-scratch__iframe {
  position: relative;
  border-radius: 30px;
  z-index: 1;
}

.l-scratch__iframe-img {
  width: 100%;
  height: 85vh;
  border: none;
}

.c-iframe__actions {
  position: fixed;
  top: 348px;
  left: 10px;
  z-index: 20;
  display: none;
  pointer-events: none;
  @include media-min($screen-tablet-portrait) {
    display: block;
    pointer-events: all;
  }
}

.c-iframe__arrow {
  display: block;
  width: 37px;
  height: 37px;
  transform: rotate(-90deg);

  &.is-for-bot {
    transform: rotate(90deg);
    margin-top: 13px;

    > svg {
      margin-left: 2px;
      margin-right: 0;
    }
  }

  > svg {
    margin-bottom: -2px;
    margin-right: 1px;
  }
}

.c-iframe__arrow-icon {
  width: 13px;
  height: 15px;
}

.l-scratch__swiper {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
  background: linear-gradient(281.53deg, #7A00FF 5.6%, #00FFF0 111.24%), linear-gradient(281.53deg, #5802B5 5.6%, #0085FF 111.24%);
}

.l-scratch__recent-bets-title-wrap {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  gap: 10px;

  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 115%;

  width: 327px;
  height: 48px;
  margin-bottom: 30px;
}

.l-scratch__recent-bets-title {
  height: 48px;
  /* or 48px */

  letter-spacing: -0.03em;
  text-transform: uppercase;

  background: linear-gradient(281.53deg, #7A00FF 5.6%, #00FFF0 111.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.l-scratch__recent-bets {
  @include media-min($screen-tablet-portrait) {
    padding: 0 40px;
  }
  @include media-min($screen-tablet-landscape-lg) {
    padding: 0 120px;
  }
  margin-bottom: 150px;
  position: relative;
}

#svg_coin_2 {
  position: absolute;
  width: 93px;
  height: 65px;
  right: 0;
  bottom: 345px;
  transform: rotate(50deg);
  @include media-min($screen-tablet-portrait) {
    opacity: 0;
  }
}

.c-battle_main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  overflow: hidden;
}

.c-battle_frame {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: black;
  overflow: hidden;
}
</style>
